import React from 'react'
import { graphql } from 'gatsby'

import styled from '@emotion/styled'
import { Jumbotron, Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'

import { myContext } from '../../provider'

const Content = styled.div`
  white-space: pre-wrap;
`

const About = ({ data }) => (
  <myContext.Consumer>
    {({ language }) => {
      const nodes = data.allContentfulCovidPage.nodes
      const aboutPageText = nodes.find((node) => node.language === language)
      return (
        <Layout>
          <SEO title="COVID" />
          <Container>
            <Content>
              <p>{aboutPageText.body.body}</p>
            </Content>
          </Container>
        </Layout>
      )
    }}
  </myContext.Consumer>
)

export const pageQuery = graphql`
  query CovidPageQuery {
    allContentfulCovidPage {
      nodes {
        body {
          body
        }
        language
      }
    }
  }
`
export default About
